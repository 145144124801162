import h from 'hyperscript';

import { createAnchorButton } from 'ui/button/button';

import { logger } from 'cadenza/utils/logging';

const PARAGRAPH_MARKER_REGEXP = /\n{2,}/g;
const LINE_BREAK_MARKER_REGEXP = /\n/g;

/**
 * Applies a limited subset of Markdown formatting to a given plain text and outputs HTML with formatting tags:
 *
 * - Fragments surrounded with '**' are replaced by fragment contents enclosed by <b> tag
 * - Fragments surrounded by '__' are replaced with their contents enclosed by <i> tag. *
 * - Single newline characters will be replaced by a <br> tag
 * - Two consecutive newline characters will be replaced by a <p> tag.
 *
 * @param str - Text which will be enhanced
 * @param leadingP - Should message be enhanced with <p> at the beginning
 * @return enhanced string with HTML tags
 * @see Published {@link https://jira.disy.net/browse/DR-293|DR-293} Customers may use this in their help tooltips.
 */
export function mark (str: string, leadingP = true) {
  const replaced = (leadingP ? '<p>' : '')
    + str.replace(PARAGRAPH_MARKER_REGEXP, '<p>')
      .replace(LINE_BREAK_MARKER_REGEXP, '<br>');

  let result = '';

  let b = false;
  let i = false;
  for (let idx = 0; idx < replaced.length; idx++) {
    const char = replaced[idx];
    const nextChar = replaced[idx + 1];

    if (char === '*' && nextChar === '*') {
      b = !b;
      result += (b ? '<b>' : '</b>');
      idx++;
    } else if (char === '_' && nextChar === '_') {
      i = !i;
      result += (i ? '<i>' : '</i>');
      idx++;
    } else {
      result += char;
    }
  }

  if (b) {
    result += '</b>';
  }
  if (i) {
    result += '</i>';
  }

  return result;
}

interface MarkOptions {
  /** Should message be enhanced with <p> at the beginning */
  leadingP?: boolean;
  /** Mapping from anchor name to its callback function */
  anchorsConfig?: Record<string, unknown>;
}

/**
 * Adds click event handlers for the anchor definitions contained in {@code str}.
 * Additionally {@code str} will be enhanced with {@code mark} function.
 *
 * @param str - Text which will be enhanced
 * @param options - options for marking
 * @return all the elements created during enhancement process
 */
export function markAllWithAnchors (str: string, { leadingP = false, anchorsConfig = {} }: MarkOptions = {}) {
  const template = h('div');
  /* eslint-disable-next-line no-unsanitized/property */
  template.innerHTML = mark(str, leadingP);
  Object.entries(anchorsConfig).forEach(([ anchorName, anchorClickCallback ]) => {
    const anchorPlaceholder = template.querySelector<HTMLAnchorElement>(`a[name=${anchorName}]`);
    if (anchorPlaceholder) {
      const configuredAnchorButton = createAnchorButton(anchorPlaceholder.textContent ?? '', { onclick: anchorClickCallback });
      anchorPlaceholder.replaceWith(configuredAnchorButton);
    } else {
      logger.warn(`Provided text was supposed to contain anchor with name: "${anchorName}".`);
    }
  });
  return [ ...template.childNodes ];
}
