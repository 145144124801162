/**
 * Adds one our more style classes to the given element.
 *
 * @param elem - Element to which classes will be added
 * @param [styleClass] - One or more style classes (without dot '.')
 */
export function addStyleClass (elem: Element, styleClass: string | string[] = []) {
  if (Array.isArray(styleClass)) {
    elem.classList.add(...styleClass);
  } else {
    elem.classList.add(styleClass);
  }
}
