import { assert } from 'cadenza/utils/custom-error';

interface Abortable {
  abort(): void;
  readonly signal: AbortSignal & { id?: string };
}
/**
 * Helper class for making sure that an operation is only being executed once at a time.
 *
 * @deprecated - Use api.js {@link singleExecution} instead.
 */
export class SingleExecutionHelper {

  _lastAbortController?: Abortable;
  _lastAbortCancelId?: string;
  _lastFinishedSuccessfullySupplier?: () => boolean;

  /**
   * Every time this method is called, the abort method on the abortController from the last call is
   * called, except if the finishedSupplier returns true.
   *
   * @param abortController - abortController to abort when a new abortController is submitted.
   * @param finishedSupplier - callback function to check if the previous operation has
   *     already finished and thus does not have to be cancelled anymore. Avoiding unnecessary cancels
   *     is especially important when BackendAbortControllers are used, because with those every abort
   *     triggers a HTTP request.
   */
  submit (abortController: Abortable, finishedSupplier = () => false) {
    assert(abortController != null, 'abortController is mandatory');
    this._cancelCurrentInternal();
    this._lastAbortController = abortController;
    this._lastFinishedSuccessfullySupplier = finishedSupplier;
  }

  cancelCurrent () {
    const canceled = this._cancelCurrentInternal();
    this._lastAbortController = undefined;
    return canceled;
  }

  get lastAbortCancelId () {
    return this._lastAbortCancelId;
  }

  _cancelCurrentInternal () {
    if (this._lastAbortController && !this._lastFinishedSuccessfullySupplier?.()) {
      this._lastAbortCancelId = this._lastAbortController.signal.id;
      this._lastAbortController.abort();
      return true;
    } else {
      return false;
    }
  }

}
